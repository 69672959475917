.bannerContainer {
  /* font-family: "Open Sans", sans-serif; */
  background-color: #f8f9fa;
}

.container {
  max-width: 1140px;
}
.personProfiles {
  object-fit: cover;
  width: 40px;
  height: 40px;
}
.shareIcons {
  width: 150px;
  height: 50px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.shareIcons:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  transition: all 0.3s ease-in-out;
}
h1 {
  font-size: 2.5rem;
  color: #343a40;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

p {
  color: #343a40;
  margin-bottom: 1rem;
}

.user-rating img {
  border-radius: 50%;
  margin-right: -10px;
  border: 3px solid #fff;
}

.fa-star {
  color: #ffc107;
}

.download-buttons img {
  margin-right: 8px;
}

.qr-code {
  border: 1px solid #a5a5a5;
  padding: 0.5rem;
  margin-top: 2rem;
  width: 50%;
  border-radius: 10px;
  background-color: #ffffffa8;
}
.qrCOdeImg {
    cursor: pointer;
    transition: transform 0.3s ease-in-out; /* Adding a smooth transition */
}

.qrCOdeImg:hover {
  transform: scale(1.6); /* Increase the scale factor for more zoom */
  z-index: 999;
}
.qr-code .downloadSubTitle {
  margin-bottom: 0.5rem;
  font-size: 12px;
}
.qr-code .downloadTitle {
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 700;
}
.qr-code .downloadSubTitle {
  margin-bottom: 0.5rem;
  font-size: 15px;
}

.template-counter {
  font-size: 2.5rem;
  background: var(--theme-light-bgcolor);
  color: #6b6b6b;
  border-radius: 8px;
  border: 1px solid #9bb2ca;
  text-align: center;
  margin-bottom: 2rem;
}

.flyer-example img {
  width: 100%;
  height: auto;
}

@media (max-width: 767.98px) {
  .template-counter {
    font-size: 1.5rem;
    padding: 0.5rem;
  }

  .qr-code {
    padding: 0.5rem;
  }

  .qr-code .downloadTitle {
    margin-bottom: 0.5rem;
    font-size: 12px;
  }

  .qr-code .downloadSubTitle {
    font-size: 0.9rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  .download-buttons img {
    width: 100px;
    height: auto;
  }

  .qr-code .downloadSubTitle {
    margin-bottom: 0.5rem;
    font-size: 11px;
  }
}
