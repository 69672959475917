.section2Container {
  /* font-family: "Open Sans", sans-serif; */
  background-color: var(--theme-color);
}
/* .section2Container h1 {
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
} */
.section2Container .flyer-description {
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}
.section2Container .card {
  border: none;
  /* margin-bottom: 30px; */
  padding: 14px;
  border-radius: 10px;
  cursor: pointer;
}
.section2Container .header-title {
  color: #ffffff;
}
.section2Container .commandButton {
  outline: none !important;
  color: var(--theme-color);
  background: #fff;
}

.section2Container .commandButton:hover {
  transition: 0.5s;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 35%);
}

.SketchesSection {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}
.section2Container .card:hover {
  box-shadow: 1.02116px 2.04233px 8.16931px 4.08466px rgba(0, 0, 0, 0.12);
}
.section2Container .card img {
  border-radius: 10px;
}
.section2Container .card-title {
  font-size: 16px;
  color: #333;
  margin-top: 15px;
  font-weight: 600;
}
.section2Container .card-text {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}
.section2Container .cardImageSection {
  height: 340px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.section2Container .grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
}
@media (min-width: 768px) {
  .section2Container .grid {
    grid-template-columns: repeat(4, 1fr);
  }
  .section2Container .cardImageSection {
    height: 240px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .SketchesSection {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
}
@media only screen and (max-width: 1200px) {
  /* .slick_Arrow>div>.slick-next {
        right: 15px !important;
    }

    .slick_Arrow>div>.slick-prev {
        left: 15px !important;
    } */
  .sketchSlider > div > .slick-prev {
    background: #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    left: -2px !important;
    z-index: 98;
  }

  .slick_Arrow > div > .slick-next {
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    height: 40px;
    width: 40px;
    right: 0px;
  }
}
.section2Container .card {
  border: none;
}
.section2Container .card img {
  border-radius: 4px;
  width: 100%;
  height: auto;
}
.section2Container .card-title {
  font-size: 16px;
  color: #333;
  margin-top: 15px;
}
.section2Container .card-text {
  font-size: 14px;
  color: #666;
}
