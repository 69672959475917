.section3Container {
    /* font-family: 'Open Sans', sans-serif; */
    background-color: #ffffff;
}
/* .section3Container h1 {
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
} */
.section3Container .flyer-description {
    color: rgba(0, 0, 0, 0.7);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.section3Container .sectionTitle{
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-transform: capitalize;
        color: #000000;
        /* margin-bottom: 14px; */
        letter-spacing: 0.5px;
}
.section3Container .sectionDescription{
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.7);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
}