.loaderCircle {
  width: 5rem /* 80px */;
  height: 5rem /* 80px */;
  border-width: 2px;
  border-radius: 9999px;
  border-style: solid;
  border-color: #3ee0eb9e;
}

.loaderInnerCircle {
  border-style: dotted;
  width: 5rem /* 80px */;
  height: 5rem /* 80px */;
  border-color: var(--theme-color);;
  border-top-width: 5px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-radius: 9999px;
  position: absolute;
  left: 0;
  top: 0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
