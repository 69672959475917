.headerContainer {
  /* font-family: "Roboto", sans-serif; */
}
.navbar {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 30px 30px;
  position: sticky;
  top: 0;
  z-index: 99;
}
.brandName {
  line-height: 25px;
}
.navbar-brand {
  font-weight: 700;
  color: #333;
  font-size: 1.55rem;
}
.brandCharName {
  color: var(--theme-color);
}
.nav-link {
  color: #333;
  margin-right: 15px;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}
.nav-link:hover {
  color: var(--theme-color) !important;
}
.nav-link:hover {
  content: "";
  display: block;
  border-bottom: 3px solid var(--theme-color);
}
.btn-get-started {
  color: white;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 1px;
}
.btn-get-started:hover {
  color: white;
  background-color: #0056b3;
  border-color: #004085;
}
/* .btn-get-started:not(:hover) span{

  transition: 0.5s;
  margin-left: 0px !important;
}

.btn-get-started{
      transition: 0.5s;
      margin-left: 5px;
} */

.navbar-toggler {
  border: 1px solid #777f8d !important;
  padding: 0.15rem 0.65rem;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  /* border: 1px solid #777f8d !important; */
}
@media (max-width: 768px) {
  .navbar {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}
