.section5Container {
  /* font-family: "Open Sans", sans-serif; */
}
.store-rating {
  background-color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  border-right: 6px solid #344154;
}
.store-rating img {
 width: 60px;
  height: 60px;
}
.store-rating::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 8px 5px 0; /* Adjust the values to change the size of the arrow */
    border-color: transparent #344154 transparent transparent; /* Set the color of the arrow */
    margin-left: 5px; /* Adjust the spacing between the arrow and the content */
    position: absolute;
    right: 0;
    top: 42px;
  }
.store-rating img {
    max-width: 100%;
    height: auto;
}
.store-rating .stars {
  color: #ffc107;
}
.make-flyer-btn {
  background-color: var(--theme-color);
  color: white;
  text-align: center;
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  margin-top: 30px;
}
.storeName {
  font-size: 12px;
  color: var(--theme-color);
}
.allreviews {
  font-size: 11px;
}
.rateNumber{
    font-size: 13px;
  color: var(--theme-color);
  display: flex;
    align-items: center;
}
.rate-star{
    font-size: 12px;
}
.starsSection{
    padding-left: 5px;
    display: flex;
    align-items: center;
}

.review-card {
    background-color: white;
    border: none;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  .review-card h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .review-card p {
    font-size: 14px;
    color: #333;
  }
  .review-card .rating {
    color: #ffc107;
    font-size: 14px;
  }
  .review-card .user-icon {
    color: #adb5bd;
    font-size: 24px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .revierName{
    font-weight: 900;
  }

  .user_profile_img{
    width: 46px;
    height: 46px;
    object-fit: cover;
    border-radius: 50px;
  }

  @media only screen and (max-width: 1200px) {
    .store-rating::after {
      border-width: 0 5px 8px 5px; /* Adjust the values to change the size of the arrow */
      border-color: transparent transparent #344154 transparent; /* Set the color of the arrow */
      position: absolute;
      bottom: -1px;
      top: inherit;
      right: 155px;
    }

    .store-rating {
      border-right: 0px;
      border-bottom: 6px solid #344154;
    }
  }