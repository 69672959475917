.footerSection {
  /* font-family: "Roboto", sans-serif; */
  background-color: #f8f9fa;
  color: #333;
}
.footer {
  background-color: #ffffff;
  border-radius: 100px 100px 0 0;
  padding: 40px;
  box-shadow: 1px 16px 41px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 0px 182px 6px rgba(0, 0, 0, 0.1);
}
.footer h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.appText{
  color: var(--theme-color)!important;
}
.footer p {
  font-size: 0.9rem;
  color: #666;
}
.footer a {
  color: #000000; /* Changed color to black */
  text-decoration: none;
}
.footer a:hover {
  text-decoration: underline;
  color: var(--theme-color)!important;
}
.footer .social-links a {
  font-size: 1.2rem;
  /* margin-right: 15px; */
  color: #000000; /* Changed color to black */
}
.footer .btn {
  background-color: var(--theme-color);
  color: #ffffff;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 700;
}
.footer .btn:hover {
  background-color: #0056b3;
}
.footer .row div {
  margin-bottom: 10px;
}
.footer .row div:last-child {
  margin-bottom: 0;
}
.footer .app-links img {
  /* height: 40px; */
  margin-right: 10px;
}
.footer .copyright {
  font-size: 0.8rem;
  color: #999;
  margin-top: 20px;
}
.footerShareIcons {
  /* width: 150px;
    height: 50px; */
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.footerShareIcons:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  transition: all 0.3s ease-in-out;
}
.sociallinksIcon{
    width: 36px;
    height: 36px;
}
@media (max-width: 767px) {
  .footer {
    border-radius: 50px 50px 0 0;
  }
}
