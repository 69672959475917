body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
  overflow-x: hidden;
}

code {
  font-family: "Roboto", sans-serif;
}

/* Apply the styles to the entire page */
body::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  border-radius: 50px;
}

/* Handle on hover and when actively scrolling */
body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px; 
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (min-width: 768px) {
  .sectionVerticalSpace {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .sectionTitleBS {
    padding-bottom: 48px;
  }
}
@media (max-width: 768px) {
  .sectionVerticalSpace {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .sectionTitleBS{
    padding-bottom: 24px;
  }
}

.redirectLink:hover{
  border: none;
  color: transparent;
}

.cursor-pointer{
  cursor: pointer
}