.section4Container {
    /* font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif; */
    background-color: var(--theme-light-bgcolor);
}
/* .section4Container h1 {
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
} */
.section4Container .flyer-description {
    color: rgba(0, 0, 0, 0.7);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}
.section4Container .card {
    border: none;
    /* margin-bottom: 30px; */
    padding: 14px;
    border-radius: 10px;
    cursor: pointer;
}
.section4Container .card:hover {
    box-shadow: 1.02116px 2.04233px 8.16931px 4.08466px rgba(0, 0, 0, 0.12);
}
.section4Container .card img {
    border-radius: 10px;
}
.section4Container .card-title {
    font-size: 16px;
    color: #333;
    margin-top: 15px;
    font-weight: 600;
}
.section4Container .card-text {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
}




.section4Container .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
}
@media (min-width: 768px) {
    .section4Container .grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
.section4Container .card {
    border: none;
}
.section4Container .card img {
    border-radius: 4px;
    width: 100%;
    height: auto;
}
.section4Container .card-title {
    font-size: 16px;
    color: #333;
    margin-top: 15px;
}
.section4Container .card-text {
    font-size: 14px;
    color: #666;
}