.commandButton {
  outline: none !important;
  color: #ffffff;
  background: var(--theme-color);
  border: none;
  border-radius: 6px;
  font-size: 21px;
  padding: 10px 40px;
  cursor: pointer;
  font-weight: 500;
}

.commandButton:hover {
  transition: 0.5s;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 35%);
}

.commandButton:not(:hover) span {
  transition: 0.5s;
  margin-left: 0px !important;
}

.commanArrow {
  width: 21px;
  height: 19px;
  transition: 0.5s;
  margin-left: 5px;
}
@media (max-width: 768px) {
  .commandButton {
    font-size: 15px;
  }
  .commanArrow img {
    width: 18px;
  }
}
