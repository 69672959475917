.slick_Arrow>div>.slick-prev {
    background: #fff;
    border-radius: 50%;
    height: 54px;
    width: 54px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    left: -32px;
    z-index: 98;
}

.slick_Arrow>div>.slick-next {
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    height: 54px;
    width: 54px;
    right: -20px;
}

.slick_Arrow>div>.slick-prev:before {
    color: #6b6b6b !important;
    border-bottom: 3px solid;
    border-left: 3px solid;
    transform: rotate(45deg);
    left: 22px;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 21px;
    content: "" !important;
    opacity: inherit !important;
}

.slick_Arrow>div>.slick-next:before {
    color: #6b6b6b !important;
    border-bottom: 3px solid;
    border-left: 3px solid;
    transform: rotate(-135deg);
    right: 23px;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 20px;
    content: "" !important;
    opacity: inherit !important;
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
    .slick_Arrow>div>.slick-next {
        right: 15px !important;
    }

    .slick_Arrow>div>.slick-prev {
        left: 15px !important;
    }
}
@media only screen and (max-width: 1200px) {
    /* .slick_Arrow>div>.slick-next {
        right: 15px !important;
    }

    .slick_Arrow>div>.slick-prev {
        left: 15px !important;
    } */
    .slick_Arrow>div>.slick-prev {
        background: #fff;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        left: -15px;
        z-index: 98;
    }
    
    .slick_Arrow>div>.slick-next {
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        height: 40px;
        width: 40px;
        right: 0px;
    }
    
    .slick_Arrow>div>.slick-prev:before {
        color: #6b6b6b !important;
        border-bottom: 3px solid;
        border-left: 3px solid;
        transform: rotate(45deg);
        left: 17px;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 15px;
        content: "" !important;
        opacity: inherit !important;
    }
    
    .slick_Arrow>div>.slick-next:before {
        color: #6b6b6b !important;
        border-bottom: 3px solid;
        border-left: 3px solid;
        transform: rotate(-135deg);
        right: 17px;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 15px;
        content: "" !important;
        opacity: inherit !important;
    }
}