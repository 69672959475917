
.section1Container {
    /* font-family: 'Open Sans', sans-serif; */
    background-color: #ffffff;
    color: #495057;
}
.feature-icon {
    font-size: 50px;
    color: var(--theme-color);
}
.feature-item {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 30px;
    height: 100%;
    padding: 20px 18px;
    background: var(--theme-light-bgcolor);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 12px 2px rgb(0 0 0 / 6%);
    border-radius: 10px;
}
.feature-item h5 {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    margin-top: 15px;
    margin-bottom: 10px;
}
.feature-item p {
    font-size: 16px;
    color: #6c757d;
    flex-grow: 1;
}
.btn-primary {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    font-weight: 600;
}
.btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}
.header-title {
    font-size: 36px;
    font-weight: 700;
    color: #23272b;
    margin-bottom: 10px;
}
.header-subtitle {
    font-size: 24px;
    color: #6c757d;
    margin-bottom: 30px;
}
@media (min-width: 768px) {
    .feature-row {
        display: flex;
        flex-wrap: wrap;
    }
    .feature-col {
        /* display: flex;
        flex: 1; */
    }
    .feature-item {
        height: calc(100% - 30px); /* Adjust height to account for margin-bottom */
    }
}
@media (max-width: 767px) {
    .feature-col {
        margin-bottom: 15px; /* Add space between boxes in mobile view */
    }
    .header-title {
        font-size: 26px;
        font-weight: 700;
        color: #23272b;
        margin-bottom: 10px;
    }
    .header-subtitle {
        font-size: 18px;
        color: #6c757d;
        margin-bottom: 30px;
    }
}